<div class="page-wraper">
                	
    <!-- HEADER START -->
    <app-section-header4></app-section-header4>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
       <!-- SLIDER START --> 
       <app-section-slider8></app-section-slider8>
        <!-- SLIDER END -->

        <!-- ABOUT SECTION START -->
        <app-section-about4 [data]="about"></app-section-about4>   
        <!-- ABOUT SECTION  SECTION END -->  
        
        <!-- SERVICE SECTION START -->
        <app-section-services6 [data]="services"></app-section-services6>
        <!-- SERVICE SECTION  SECTION END --> 
        
        <!-- WHAT WE DO SECTION START -->
        <app-section-what-we-do4 [data]="whatWeDo"></app-section-what-we-do4>
        <!-- WHAT WE DO SECTION END -->
        
        <!-- VIDEO SECTION START -->
        <app-section-video5 [data]="video"></app-section-video5>
        <!-- VIDEO  SECTION END -->              
        
        <!-- LATEST PRJECTS SLIDER START -->
        <app-section-projects6 [data]="projects"></app-section-projects6>
        <!-- LATEST PRJECTS SLIDER END -->              
        
        <!-- TESTIMONIAL SECTION START -->
        <app-section-testimonials4 [data]="testimonials"></app-section-testimonials4>
        <!-- TESTIMONIAL SECTION END -->                

        <!-- OUR TEAM START -->
        <app-section-team2 [data]="team" bgcover="0" grayBox="0"></app-section-team2>   
        <!-- OUR TEAM SECTION END --> 
        
        <!-- OUR BLOG START -->
        <app-section-blogs6 [data]="blogs"></app-section-blogs6>
        <!-- OUR BLOG END -->                                                
   
        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients1 [data]="clients"></app-section-clients1>
        <!-- CLIENT LOGO  SECTION End -->                            
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer3></app-section-footer3>
    <!-- FOOTER END -->

 </div>

<app-loader></app-loader>
