<footer class="site-footer footer-large footer-dark text-white footer-style1" >


    <!-- FOOTER BLOCKES START -->
    <div class="footer-top bg-no-repeat bg-bottom-right" style="background-image:url(assets/images/background/footer-bg.png)">
        <div class="container">
            <div class="row">

                <div class="col-lg-5 col-md-12 col-sm-12">
                    <div class="footer-h-left">
                        <div class="widget widget_about">
                            <div class="logo-footer clearfix" style="padding-left:50px; width:150px; height:100px;">
                                <a href="#"><img  src="assets/images/ecoregen3.svg" alt="" ></a>
                            </div>
                            <p>Ecoregen is a pioneering force in transformer oil regeneration and advanced equipment design. Our founders bring unmatched expertise in the field, specializing in the regeneration of transformer oil and the creation of high-efficiency regeneration systems. With a commitment to innovation and sustainability, Ecoregen has become a leader in developing, designing, and manufacturing state-of-the-art oil regeneration solutions that are both effective and environmentally responsible. At Ecoregen, we understand the critical role that clean, high-quality transformer oil plays in maintaining the performance and longevity of transformers.</p>
                         </div>
                        <div class="widget recent-posts-entry">
                           <ul class="widget_address">
                                <li><i class="fa fa-map-marker"></i>Office in Dubai, Toronto, Dallas</li>
                                <li><i class="fa fa-envelope"></i>sales@ecoregen.com</li>
                                <li><i class="fa fa-envelope"></i>karthick@ecoregen.com</li>
                                <li><i class="fa fa-envelope"></i>prabha@ecoregen.com</li>
                                <li> <i class="fa fa-phone"></i>Canada: +1 (289) 212-6662 </li>
                            </ul>
                        </div>
                        <!-- <ul class="social-icons  wt-social-links footer-social-icon">
                            <li><a href="https://www.google.com/" class="fa fa-google"></a></li>
                            <li><a href="https://rss.com/" class="fa fa-rss"></a></li>
                            <li><a href="https://www.facebook.com/" class="fa fa-facebook"></a></li>
                            <li><a href="https://twitter.com/" class="fa fa-twitter"></a></li>
                            <li><a href="https://www.linkedin.com/" class="fa fa-linkedin"></a></li>
                        </ul> -->
                        <ul class="social-icons  wt-social-links footer-social-icon">
                          <li><a href="https://www.linkedin.com/in/ecoregen-systems-005b4a2a8" class="fa fa-linkedin"></a></li>
                        </ul>
                    </div>

                </div>

                <div class="col-lg-7 col-md-12 col-sm-12">
                    <div class="row footer-h-right">
                        <!-- <div class="col-lg-5 col-md-4">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Useful links</h3>
                                <ul>
                                    <li><a routerLink="/about/about1">About</a></li>
                                    <li><a routerLink="/other/our-prices">Pricing Plan </a></li>
                                    <li><a routerLink="/other/faq">FAQ</a></li>
                                    <li><a routerLink="/other/team-single">Our Team </a></li>
                                    <li><a routerLink="/services/services1">Services </a></li>
                                </ul>
                            </div>
                        </div> -->

                        <div class="col-lg-7 col-md-8">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Our Services</h3>
                                <ul>
                                    <li><a href="#">Oil & Gas Factory</a>
                                        <a href="#">Regeneration Systems</a></li>
                                    <li><a href="#">TO Treatment systems</a>
                                        <a href="#">Corrosive sulfur removal</a></li>
                                    <li><a href="#">Mechanical</a>
                                        <a rhref="#">TO Regeneration systems </a></li>
                                    <li><a href="#">Oil purification equipment</a>
                                        <a href="#">Oil regeneration equipment</a></li>
                                    <li><a href="#">Oil treatment equipment </a>
                                        <a href="#">Sorbet reactivation  </a></li>
                                      <li><a href="#">Coalescer based plants</a>
                                            <a href="#">REOIL & FILOIL equipment  </a></li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <!-- <div class="widget widget_newsletter">
                        <h3 class="widget-title">Newsletter</h3>
                            <p>Subscribe to our newsletter to receive latest news on our services.</p>
                            <div class="newsletter-input">
                              <div class="input-group">
                                <input id="email" type="text" class="form-control" name="email" placeholder="Enter your email">
                                <div class="input-group-append">
                                  <button type="submit" class="input-group-text nl-search-btn text-black site-bg-primary title-style-2">Subscribe</button>
                                </div>
                              </div>
                            </div>
                    </div> -->

                </div>

            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->

    <!-- <div class="footer-bottom">
      <div class="container">
        <div class="wt-footer-bot-left d-flex justify-content-between">
            <span class="copyrights-text">Copyright © 2021 <span class="site-text-primary">Thewebmax</span></span>
            <ul class="copyrights-nav">
                <li><a routerLink="/about/about1">Terms  &amp; Condition</a></li>
                <li><a routerLink="/about/about2">Privacy Policy</a></li>
                <li><a routerLink="/other/contact1">Contact Us</a></li>
            </ul>
        </div>
      </div>
    </div> -->


</footer>
