<div class="section-full video-counter-section p-t80 {{data.bgColor}}">

    <div class="video-counter-bg-white">
            <div class="container">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer text-center">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>{{data.subtitle}}</h2>
                </div>
                <!-- TITLE END-->
            </div>
     </div>

    <div class="video-counter-bg-image overlay-wraper bg-cover bg-no-repeat" style="background-image:url({{data.bgImage}});">
        <div class="overlay-main site-bg-secondry opacity-{{data.bgOpacity}}"></div>

            <div class="video-section-outer mfp-gallery">
                <!-- <div class="video-section">
                     <a href="{{data.videolink}}" class="mfp-video play-now">
                        <i class="icon fa fa-play"></i>
                        <span class="ripple"></span>
                    </a>
                </div> -->
            </div>

            <div class="container">
                <div class="counter-section-outer-top">
                    <div class="counter-outer">

                        <div class="row justify-content-center">

                            <div class="col-lg-4 col-md-4 m-b30" *ngFor="let object of data.solutions">
                                <div class="wt-icon-box-wraper center">
                                    <h2 class="counter site-text-primary">{{object.count}}</h2>
                                    <span class="site-text-white title-style-2">{{object.title}}</span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="counter-section-outer-bottom">


                        <div class="row justify-content-center">

                            <div class="col-lg-5 col-md-6 m-b30 ">
                                <div class="video-section-info site-bg-primary site-text-white">
                                    <h3 class="wt-tilte site-text-white">{{data.description.title}}</h3>
                                    <p>{{data.description.subtitle}}</p>
                                    <!-- <a routerLink="/services/s-mechanical" class="site-button-link">Read More</a> -->
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-6 m-b30">
                                <div class="video-section-blockquote">
                                    <i class="fa fa-quote-left"></i>
                                    <span class="q-author-detail site-text-white title-style-2">{{data.description.quote}}</span>
                                    <div class="q-author-name site-text-primary title-style-2">{{data.description.quoteby}}</div>
                                </div>
                            </div>



                        </div>

                </div>
            </div>

    </div>

</div>
