<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- ABOUT SECTION START -->
        <app-section-about1 [data]="about"></app-section-about1>
        <!-- ABOUT SECTION  SECTION END -->

        <!-- ALL SERVICES START -->
        <app-section-services1 [data]="services"></app-section-services1>
        <!-- ALL SERVICES SECTION END -->

         <!-- VIDEO SECTION START -->
        <app-section-video1 [data]="video"></app-section-video1>
        <!-- VIDEO SECTION  SECTION END -->

        <!-- OUR TEAM START -->
        <app-section-team1 [data]="team" bgcover="0" grayBox="0"></app-section-team1>
        <!-- OUR TEAM SECTION END -->

        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients1 [data]="clients"></app-section-clients1>
        <!-- CLIENT LOGO  SECTION End -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
