<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENTG START -->

           <!-- CONTACT FORM -->
         <div class="section-full  p-t80 p-b50 bg-cover" style="background-color:url(assets/images/background/bg-7.jpg)">
            <div class="section-content">
                <div class="container">
                    <div class="contact-one">
                        <!-- CONTACT FORM-->
                        <div class="row  d-flex justify-content-center flex-wrap">

                            <div >
                                <form  class="cons-contact-form" method="post" action="../../../handlers/form-handler2.php">
                                    <!-- TITLE START -->
                                    <!-- <div class="section-head left wt-small-separator-outer">
                                        <div class="wt-small-separator site-text-primary">
                                            <div class="sep-leaf-left"></div>
                                            <div>Contact Form</div>
                                            <div class="sep-leaf-right"></div>
                                        </div>
                                        <h2>Get In Touch</h2>
                                    </div> -->
                                    <!-- TITLE END -->

                                    <!-- <div class="row">
                                       <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <input name="username" type="text" required class="form-control" placeholder="Name">
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                               <input name="email" type="text" class="form-control" required placeholder="Email">
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <input name="phone" type="text" class="form-control" required placeholder="Phone">
                                             </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                 <input name="subject" type="text" class="form-control" required placeholder="Subject">
                                             </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                               <textarea name="message" class="form-control" rows="4" placeholder="Message"></textarea>
                                             </div>
                                        </div>

                                       <div class="col-md-12">
                                            <button type="submit" class="site-button site-btn-effect">Submit Now</button>
                                        </div>

                                    </div> -->
                               </form>
                            </div>

                            <div class="col-lg-12 col-md-12 m-b30">
                                <div class="contact-info">
                                    <div class="contact-info-inner">

                                        <!-- TITLE START-->
                                        <div class="section-head left wt-small-separator-outer">
                                            <div class="wt-small-separator site-text-primary">
                                                <div class="sep-leaf-left"></div>
                                                <div>Contact info</div>
                                                <div class="sep-leaf-right"></div>
                                            </div>
                                            <h2>Our Full Info</h2>
                                        </div>
                                        <!-- TITLE END-->

                                        <div class="contact-info-section" style="background-image:url(assets/images/background/bg-map2.png);">

                                                <div class="wt-icon-box-wraper left m-b30">

                                                    <div class="icon-content">

                                                        <h3 class="m-t0 site-text-primary">Phone number</h3>
                                                        <p>Canada: +1 (289) 212-6662</p>
                                                    </div>
                                                </div>

                                                <div class="wt-icon-box-wraper left m-b30">

                                                    <div class="icon-content">
                                                        <h3 class="m-t0 site-text-primary">Email address</h3>
                                                        <p>sales@ecoregen.com</p>
                                                        <p>karthick@ecoregen.com</p>
                                                        <p>prabha@ecoregen.com</p>
                                                    </div>
                                                </div>

                                                <div class="wt-icon-box-wraper left m-b30">

                                                    <div class="icon-content">
                                                        <h3 class="m-t0 site-text-primary">Address info</h3>
                                                        <p>Office in Dubai, Toronto, Dallas</p>
                                                        <!-- <p>1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p> -->
                                                    </div>
                                                </div>

                                                <div class="wt-icon-box-wraper left">

                                                    <div class="icon-content">
                                                        <h3 class="m-t0 site-text-primary">Opening Hours</h3>
                                                        <ul class="list-unstyled m-b0">
                                                          <li>Mon-Fri: 8 am – 7 pm</li>
                                                          <li>Saturday: 9 am – 5 pm</li>
                                                          <li>Sunday: Closed</li>
                                                        </ul>
                                                    </div>
                                                </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

           <!-- GOOGLE MAP -->
         <!-- <div class="section-full bg-white p-tb80">
            <div class="section-content">
                <div class="container">
                    <div class="gmap-outline">

                        <google-map  height="500px" width="100%" embed="51.153602246510765, -82.99218791113556"></google-map>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
