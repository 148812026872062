<div class="page-wraper">
                	
    <!-- HEADER START -->
    <app-section-header7></app-section-header7>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- SLIDER START --> 
        <app-section-slider9></app-section-slider9>
        <!-- SLIDER END -->  
       
        <!-- ABOUT ONE SECTION START -->
        <app-section-about5 [data]="about"></app-section-about5>
        <!-- ABOUT ONE SECTION END --> 
  
        <!-- SERVICE SECTION START -->
        <app-section-services7 [data]="services"></app-section-services7>
        <!-- SERVICES SECTION END --> 

        <!-- QUALITY SECTION START -->
        <app-section-quality1 [data]="quality"></app-section-quality1>
        <!-- QUALITY SECTION END -->  

        <!-- OUR TEAM START -->
        <app-section-team3 [data]="team"></app-section-team3>
        <!-- OUR TEAM SECTION END -->             

        <!-- PROJECT SECTION START -->
        <app-section-projects7 [data]="projects"></app-section-projects7>
        <!-- PROJECT SECTION END -->            
        
        <!-- OUR BLOG START -->
        <app-section-blogs7 [data]="blogs"></app-section-blogs7>   
        <!-- OUR BLOG END -->                                                
   
        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients1 [data]="clients"></app-section-clients1>
        <!-- CLIENT LOGO  SECTION End -->                            
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer3></app-section-footer3>
    <!-- FOOTER END -->

 </div>

<app-loader></app-loader>
