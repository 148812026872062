<div class="page-wraper">
           	
    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
       <!-- SLIDER START --> 
        <app-section-slider1></app-section-slider1>
        <!-- SLIDER END -->
                         
        <!-- ABOUT SECTION START -->
        <app-section-about1 [data]="about"></app-section-about1>   
        <!-- ABOUT SECTION  SECTION END -->  

        <!-- SERVICES SECTION START -->
        <app-section-services1 [data]="services"></app-section-services1>
        <!-- SERVICES SECTION END -->  
        
         <!-- WHAT WE DO SECTION START -->
        <app-section-what-we-do1 [data]="whatwedo"></app-section-what-we-do1>
        <!-- WHAT WE DO SECTION END -->             
                  
        <!-- VIDEO SECTION START -->
        <app-section-video1 [data]="video"></app-section-video1>
        <!-- VIDEO SECTION  SECTION END -->              
        
        <!-- OUR PROJECTS START -->
        <app-section-projects1 [data]="projects"></app-section-projects1>  
        <!-- OUR PROJECTS END -->                              

        <!-- OUR TEAM START -->
        <app-section-team1 [data]="team"></app-section-team1>  
        <!-- OUR TEAM SECTION END --> 
        
        <!-- TESTIMONIAL SECTION START -->
        <app-section-testimonials1 [data]="testimonials"></app-section-testimonials1>
        <!-- TESTIMONIAL SECTION END -->              
                    
        <!-- OUR BLOG START -->
        <app-section-blogs1 [data]="blogs" wide="1"></app-section-blogs1>  
        <!-- OUR BLOG END -->
   
        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients1 [data]="clients"></app-section-clients1>
        <!-- CLIENT LOGO  SECTION End -->                            
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
    
 </div>

<app-loader></app-loader>
