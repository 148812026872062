<header class="site-header header-style-1 mobile-sider-drawer-menu">

    <!-- SITE Search -->
    <div id="search-toggle-block">
        <div id="search">
            <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                <div class="input-group">
                    <input class="form-control" value="" name="q" type="search" placeholder="Type to search"/>
                    <span class="input-group-append"><button type="button" class="search-btn"><i class="fa fa-search"></i></button></span>
                </div>
            </form>
        </div>
    </div>

    <div class="top-bar site-bg-secondry">
        <div class="container">

                <div class="d-flex justify-content-between">
                    <div class="wt-topbar-left d-flex flex-wrap align-content-start">
                        <ul class="wt-topbar-info e-p-bx text-white">
                            <li><span> Monday - Saturday</span><span>8AM -7PM</span></li>
                            <li><i class="fa fa-phone"></i>+1 289-212-6662</li>
                            <li><i class="fa fa-envelope"></i>sales@ecoregen.com</li>
                        </ul>
                    </div>

                    <!-- <div class="wt-topbar-right d-flex flex-wrap align-content-center">
                        <div class="header-search">
                            <a href="javascript:void(0);" class="header-search-icon"><i class="fa fa-search"></i></a>
                        </div>
                    </div> -->
                </div>

        </div>
    </div>


    <div class="container header-middle clearfix">
        <div class="logo-header">
            <div class="logo-header-inner logo-header-one" style="height:50px;">
                <a routerLink="/index">
                <img  src="assets/images/ecoregen.png" alt="" />
                </a>
            </div>
        </div>

        <div class="header-info">
            <ul>
                <li>
                    <div class="icon-md">
                        <span class="icon-cell"><i class="flaticon-trophy"></i></span>
                    </div>
                    <div class="icon-content">
                        <strong>The Best Industrial</strong>
                        <span>Solution Provider</span>
                    </div>
                </li>
                <li>

                    <div class="icon-md">
                        <span class="icon-cell"><i class="flaticon-stamp"></i></span>
                    </div>
                    <div class="icon-content">
                        <strong>Certified Company</strong>
                        <span>ISO 9001-2021</span>
                    </div>

                </li>
            </ul>
        </div>

   </div>

    <div class="sticky-header main-bar-wraper  navbar-expand-lg">
        <div class="main-bar">
               <div class="container clearfix">
                    <!-- NAV Toggle Button -->
                    <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggler collapsed">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar icon-bar-first"></span>
                        <span class="icon-bar icon-bar-two"></span>
                        <span class="icon-bar icon-bar-three"></span>
                    </button>

                    <!-- MAIN Vav -->
                    <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">

                        <ul class=" nav navbar-nav">
                            <li><a routerLink="/index">Home</a>
                                <!-- <ul class="sub-menu">
                                            <li><a routerLink="/index">Home Industries</a></li>
                                            <li><a routerLink="/index2">Home Factory</a></li>
                                            <li><a routerLink="/index3">Home Construction</a></li>
                                            <li><a routerLink="/index4">Home Transport</a></li>
                                            <li><a routerLink="/index5">Home Agriculture</a></li>
                                            <li><a routerLink="/index6">Home Solar Energy</a></li>
                                            <li><a routerLink="/index7">Home Oil/Gas Plant</a></li>
                                            <li><a routerLink="/index8">Home Page 8</a></li>
                                            <li><a routerLink="/index9">Home Industries 9</a></li>
                                            <li><a routerLink="/index10">Home Factory 10</a></li>
                                            <li><a routerLink="/index11">Home Construction 11 <span class="new_blink">New</span></a></li>
                                            <li><a routerLink="/index12">Air Conditioning Repair 12 <span class="new_blink">New</span></a></li>
                                </ul> -->
                            </li>

                            <li><a routerLink="/about/about1">About</a>
                                <!-- <ul class="sub-menu">
                                    <li><a routerLink="/about/about1">About 1</a></li>
                                    <li><a routerLink="/about/about2">About 2</a></li>
                                </ul> -->
                            </li>
                            <li><a routerLink="/services/services1">Services</a>
                                <!-- <ul class="sub-menu">
                                    <li><a routerLink="/services/services1">Services one</a></li>
                                    <li><a routerLink="/services/services2">Services Two</a></li>
                                    <li><a routerLink="/services/s-agricultural">Agricultural Automation</a></li>
                                    <li><a routerLink="/services/s-automotive">Automotive Manufacturing</a></li>
                                    <li><a routerLink="/services/s-chemical">Chemical Research</a></li>
                                    <li><a routerLink="/services/s-civil">Civil Engineering</a></li>
                                    <li><a routerLink="/services/s-mechanical">Mechanical Engineering</a></li>
                                    <li><a routerLink="/services/s-oilgas">Oil & Gas Engineering</a></li>
                                    <li><a routerLink="/services/s-power-energy">Power & Energy Sector</a></li>

                                </ul> -->
                            </li>
                            <!-- <li><a href="javascript:void(0);">Project</a>
                                <ul class="sub-menu">
                                    <li><a routerLink="/projects/project-grid">Project Grid</a></li>
                                    <li><a routerLink="/projects/project-masonry">Project Masonry</a></li>
                                    <li><a routerLink="/projects/project-carousel">Project Carousel</a></li>
                                    <li><a routerLink="/projects/project-detail">Project Detail</a>
                                </ul>
                           </li> -->
                            <!-- <li><a href="javascript:void(0);">Shop</a>
                                <ul class="sub-menu">
                                    <li><a routerLink="/products/product">Shop</a></li>
                                    <li><a routerLink="/products/product-detail">Shop Detail</a></li>
                                    <li><a routerLink="/products/shopping-cart">Shopping Cart</a></li>
                                    <li><a routerLink="/products/checkout">Checkout</a></li>
                                    <li><a routerLink="/products/wish-list">Wishlist</a></li>
                                </ul>
                           </li>
                            <li><a href="javascript:void(0);">Blog</a>
                                <ul class="sub-menu">
                                    <li><a routerLink="/blog/blog-grid">Blog Grid</a></li>
                                    <li><a routerLink="/blog/blog-list-sidebar">Blog List</a></li>
                                    <li><a routerLink="/blog/blog-post-right-sidebar">Blog Post</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Pages</a>
                                <ul class="sub-menu">
                                    <li><a routerLink="/other/our-prices">Pricing Plan</a></li>
                                    <li><a routerLink="/other/icon-font">Icon Font</a></li>
                                    <li><a routerLink="/other/team">Team</a></li>
                                    <li><a routerLink="/other/team-single">Team Single</a></li>
                                    <li><a routerLink="/other/faq">FAQ</a></li>
                                    <li><a routerLink="/other/error-403">Error 403</a></li>
                                    <li><a routerLink="/other/error-404">Error 404</a></li>
                                    <li><a routerLink="/other/error-405">Error 405</a></li>
                                </ul>
                            </li> -->
                            <li><a routerLink="/other/contact1">Contact us</a></li>
                        </ul>

                    </div>


                </div>
            </div>
        </div>



</header>
